import companiesData from '../data/companies.json';

const firstNames = ['John', 'Sarah', 'Michael', 'Emma', 'David', 'Lisa', 'James', 'Jennifer', 'Robert', 'Emily'];
const lastNames = ['Smith', 'Johnson', 'Brown', 'Wilson', 'Taylor', 'Davies', 'Evans', 'Thomas', 'Roberts', 'Walker'];
const statuses = ['New', 'Trial', 'Current', 'Lost'];

const generateMockContact = (id) => {
  const companies = companiesData.Export.map(company => company['Company Name']);
  const company = companiesData.Export[Math.floor(Math.random() * companiesData.Export.length)];
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  
  return {
    id,
    name: `${firstName} ${lastName}`,
    companyId: company['Company ID'],
    companyName: company['Company Name'],
    province: company.Province,
    company: companies[Math.floor(Math.random() * companies.length)],
    leadStatus: statuses[Math.floor(Math.random() * statuses.length)],
    // email: `${firstName.toLowerCase()}.${lastName.toLowerCase()}@${company['Company Name'].toLowerCase().replace(/[^a-z0-9]/g, '')}.com`,
    email: `${firstName.toLowerCase()}.${lastName.toLowerCase()}@royallepage.com`,
    phone: `(${Math.floor(Math.random() * 900) + 100}) ${Math.floor(Math.random() * 900) + 100}-${Math.floor(Math.random() * 9000) + 1000}`,
    status: Math.random() > 0.1 ? 'Active' : 'Inactive', // 90% active
    department: Math.random() > 0.7 ? 'Support' : 'Sales', // 70% sales
  };
};

// Generate 100 mock contacts
export const mockContacts = Array.from({ length: 100 }, (_, i) => generateMockContact(i + 1));

// Generate notes that reference the mock contacts
export const mockNotes = [
  {
    id: 1,
    contactId: 1,
    title: 'Initial Contact',
    content: `Called ${mockContacts[0].name} regarding onboarding at ${mockContacts[0].companyName}`,
    type: 'Call',
    status: 'Active',
    createdAt: '2024-01-15T10:30:00Z',
    tags: ['onboarding', 'new-contact'],
    attachments: [
      {
        id: 1,
        name: 'onboarding-checklist.pdf',
        url: '/files/onboarding-checklist.pdf',
        type: 'application/pdf'
      }
    ]
  },
  {
    id: 2,
    contactId: 2,
    title: 'Training Session',
    content: `Completed initial training with ${mockContacts[1].name} for ${mockContacts[1].companyName}`,
    type: 'Meeting',
    status: 'Active',
    createdAt: '2024-01-14T15:45:00Z',
    tags: ['training', 'completed'],
    attachments: []
  },
  {
    id: 3,
    contactId: 3,
    title: 'Support Request',
    content: `${mockContacts[2].name} from ${mockContacts[2].companyName} requested assistance with login issues`,
    type: 'Email',
    status: 'Active',
    createdAt: '2024-01-13T09:15:00Z',
    tags: ['support', 'login-issue'],
    attachments: [
      {
        id: 2,
        name: 'screenshot.png',
        url: '/files/screenshot.png',
        type: 'image/png'
      }
    ]
  },
  {
    id: 4,
    contactId: 4,
    title: 'Quarterly Review',
    content: `Conducted quarterly performance review with ${mockContacts[3].name}`,
    type: 'Meeting',
    status: 'Hidden',
    createdAt: '2024-01-12T14:20:00Z',
    tags: ['review', 'quarterly'],
    attachments: []
  }
];

const searchContacts = (query) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const normalizedQuery = query.toLowerCase();
      const results = mockContacts.filter(contact => 
        contact.name.toLowerCase().includes(normalizedQuery) ||
        contact.company.toLowerCase().includes(normalizedQuery)
      );
      resolve(results);
    }, 150); // Simulate network delay
  });
};

export const mockDataService = {
  getContactById: (id) => Promise.resolve(mockContacts.find(contact => contact.id === parseInt(id))),
  getContactNotes: (contactId) => Promise.resolve(mockNotes.filter(note => note.contactId === contactId)),
  getContacts: () => Promise.resolve(mockContacts),
  getNotes: () => Promise.resolve(mockNotes),
  getNoteById: (id) => Promise.resolve(mockNotes.find(note => note.id === id)),
  updateNote: (noteId, updates) => {
    return new Promise((resolve) => {
      const noteIndex = mockNotes.findIndex(note => note.id === noteId);
      if (noteIndex !== -1) {
        mockNotes[noteIndex] = { ...mockNotes[noteIndex], ...updates };
        resolve(mockNotes[noteIndex]);
      }
    });
  },
  deleteNote: (noteId) => {
    return new Promise((resolve) => {
      const noteIndex = mockNotes.findIndex(note => note.id === noteId);
      if (noteIndex !== -1) {
        mockNotes[noteIndex] = { ...mockNotes[noteIndex], status: 'Hidden' };
        resolve(mockNotes[noteIndex]);
      }
    });
  },
  addNote: (note) => {
    return new Promise((resolve) => {
      const newNote = {
        id: Math.max(...mockNotes.map(n => n.id)) + 1,
        createdAt: new Date().toISOString(),
        status: 'Active',
        ...note
      };
      mockNotes.push(newNote);
      resolve(newNote);
    });
  },
  getBrokerages: () => {
    return Promise.resolve(
      companiesData.Export.map(company => ({
        id: company['Company ID'],
        name: company['Company Name'],
        province: company.Province
      }))
    );
  },
  searchContacts,
};
