import { useTheme } from '../context/ThemeContext';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import NewNote from './NewNote';
import { mockDataService } from '../services/mockData';

function Navbar({ toggleRightPanel, isRightPanelOpen }) {
  const { isDark, toggleTheme } = useTheme();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const searchTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const resultsListRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Debounced search function
  const debouncedSearch = useCallback((query) => {
    setIsSearching(true);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      if (query.trim()) {
        mockDataService.searchContacts(query)
          .then(results => {
            setSearchResults(results.slice(0, 5)); // Limit to 5 results
            setIsSearching(false);
          });
      } else {
        setSearchResults([]);
        setIsSearching(false);
      }
    }, 300);
  }, []);

  // Handle input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setSelectedIndex(-1);
    debouncedSearch(value);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (!searchResults.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault(); // Prevent page scroll
        setSelectedIndex(prev => {
          const nextIndex = prev < searchResults.length - 1 ? prev + 1 : prev;
          // Scroll selected item into view
          setTimeout(() => {
            const selectedElement = resultsListRef.current?.children[nextIndex];
            selectedElement?.scrollIntoView({ block: 'nearest' });
          }, 0);
          return nextIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault(); // Prevent page scroll
        setSelectedIndex(prev => {
          const nextIndex = prev > -1 ? prev - 1 : -1;
          // Scroll selected item into view
          setTimeout(() => {
            const selectedElement = resultsListRef.current?.children[nextIndex];
            selectedElement?.scrollIntoView({ block: 'nearest' });
          }, 0);
          return nextIndex;
        });
        break;
      case 'Enter':
        if (selectedIndex >= 0) {
          handleResultClick(searchResults[selectedIndex]);
        }
        break;
      case 'Escape':
        setSearchResults([]);
        setSelectedIndex(-1);
        break;
      default:
        break;
    }
  };

  // Handle result click
  const handleResultClick = (contact) => {
    setSearchValue(''); // Clear the search input
    setSearchResults([]); // Clear the search results
    setSelectedIndex(-1); // Reset the selected index
    navigate(`/contacts/${contact.id}`); // Navigate to contact detail page
  };

  // Close search results when clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest('.search-container')) {
        setSearchResults([]);
        setSelectedIndex(-1);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-60 md:relative md:z-auto">
        <div className="bg-white dark:bg-gray-800">
          <div className="flex items-center justify-center md:justify-start px-4 py-2 md:px-6">
            {/* Search bar with dropdown */}
            <div className="relative w-full max-w-[400px] md:max-w-lg search-container">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input
                type="text"
                value={searchValue}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                placeholder="Search contacts..."
                className="block w-full pl-11 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
              />
              
              {/* Search Results Dropdown */}
              {(searchResults.length > 0 || isSearching) && (
                <div className="absolute mt-1 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg border border-gray-200 dark:border-gray-700 z-50">
                  {isSearching ? (
                    <div className="px-4 py-3 text-sm text-gray-500 dark:text-gray-400">
                      Searching...
                    </div>
                  ) : (
                    <ul 
                      ref={resultsListRef}
                      className="max-h-60 overflow-auto"
                      role="listbox"
                    >
                      {searchResults.map((result, index) => (
                        <li
                          key={result.id}
                          onClick={() => handleResultClick(result)}
                          className={`px-4 py-3 text-sm cursor-pointer ${
                            index === selectedIndex
                              ? 'bg-blue-50 dark:bg-blue-900/50'
                              : 'hover:bg-gray-50 dark:hover:bg-gray-700/50'
                          }`}
                        >
                          <div className="font-medium text-gray-900 dark:text-gray-100">
                            {result.name}
                          </div>
                          <div className="text-gray-500 dark:text-gray-400">
                            {result.company}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>

            {/* Desktop-only buttons */}
            <div className="hidden md:flex items-center space-x-4 ml-4">
              {/* Add Note button */}
              <button
                onClick={toggleSidebar}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
                Add Note
              </button>
            </div>

            {/* Right side items */}
            <div className="flex items-center ml-4 md:ml-auto">
              {/* Chat button */}
              <button 
                onClick={toggleRightPanel}
                className={`w-10 h-10 rounded-lg flex items-center justify-center mr-2 transition-colors duration-200 ${
                  isRightPanelOpen 
                    ? 'bg-blue-600 text-white hover:bg-blue-700' 
                    : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white'
                }`}
                aria-label="Toggle Chat Panel"
              >
                <div className="w-6 h-6 flex items-center justify-center">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                  </svg>
                </div>
              </button>

              {/* Avatar */}
              <button className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                <svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </button>

              {/* Theme toggle - desktop only */}
              <button
                onClick={toggleTheme}
                className="hidden md:flex p-2 rounded-md text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 ml-4"
              >
                {isDark ? (
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                ) : (
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* NewNote component */}
        <NewNote isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </nav>
    </>
  );
}

export default Navbar;
