
const ContactDetails = ({ contact }) => (
  <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700/50">
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Address</h4>
        <p className="text-sm text-gray-900 dark:text-gray-100">{contact.address || 'N/A'}</p>
      </div>
      <div>
        <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Last Contact</h4>
        <p className="text-sm text-gray-900 dark:text-gray-100">{contact.lastContact || 'N/A'}</p>
      </div>
      <div>
        <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Notes</h4>
        <p className="text-sm text-gray-900 dark:text-gray-100">{contact.notes || 'No notes available'}</p>
      </div>
    </div>
  </div>
);

export default ContactDetails;