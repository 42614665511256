import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { mockDataService } from '../services/mockData';

function ContactDetails() {
  const { id } = useParams();
  const [contact, setContact] = useState(null);
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [contactData, notesData] = await Promise.all([
          mockDataService.getContactById(parseInt(id)), // Parse id to integer
          mockDataService.getContactNotes(parseInt(id))
        ]);
        setContact(contactData);
        setNotes(notesData);
      } catch (error) {
        console.error('Error fetching contact details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleAddNote = async (e) => {
    e.preventDefault();
    
    const newNote = {
      contactId: parseInt(id),
      title: 'Quick Note',
      content: e.target.note.value,
      type: 'Note',
      tags: []
    };

    try {
      const addedNote = await mockDataService.addNote(newNote);
      setNotes(prev => [addedNote, ...prev]);
      e.target.reset();
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  if (isLoading) return <div className="p-6 text-xs text-gray-900 dark:text-gray-100">Loading...</div>;
  if (!contact) return <div className="p-6 text-xs text-gray-900 dark:text-gray-100">Contact not found</div>;

  return (
    <div className="p-6 space-y-8 font-sans transition-colors duration-200 dark:bg-gray-900">
      {/* Contact Info Card */}
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
        <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">{contact.name}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600 dark:text-gray-300 text-xs">
              <span className="text-xs font-medium text-gray-900 dark:text-white">Company:</span> {contact.companyName}
            </p>
            <p className="text-gray-600 dark:text-gray-300 text-xs">
              <span className="text-xs font-medium text-gray-900 dark:text-white">Province:</span> {contact.province}
            </p>
          </div>
          <div>
            <p className="text-gray-600 dark:text-gray-300 text-xs">
              <span className="text-xs font-medium text-gray-900 dark:text-white">Email:</span> {contact.email}
            </p>
            <p className="text-gray-600 dark:text-gray-300 text-xs">
              <span className="text-xs font-medium text-gray-900 dark:text-white">Phone:</span> {contact.phone}
            </p>
          </div>
        </div>
      </div>

      {/* Add Note Form */}
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
        <h3 className="text-xs font-medium text-gray-900 dark:text-white mb-4">Add Note</h3>
        <form onSubmit={handleAddNote} className="space-y-4">
          <div>
            <textarea
              name="note"
              className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
              rows="3"
              placeholder="Enter your note here..."
              required
            />
          </div>
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            Add Note
          </button>
        </form>
      </div>

      {/* Notes History */}
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
        <h3 className="text-xs font-medium text-gray-900 dark:text-white mb-4">Notes History</h3>
        <div className="divide-y divide-gray-200 dark:divide-gray-700 space-y-4">
          {notes.map(note => (
            <div key={note.id} className="pt-4 first:pt-0">
              <div className="flex justify-between items-start mb-2">
                <h4 className="text-xs font-medium text-gray-900 dark:text-white">{note.title}</h4>
                <span className="text-gray-600 dark:text-gray-300 text-xs">
                  {new Date(note.createdAt).toLocaleString()}
                </span>
              </div>
              <p className="text-gray-600 dark:text-gray-300 text-xs">{note.content}</p>
              {note.tags.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {note.tags.map(tag => (
                    <span 
                      key={tag} 
                      className="px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-xs"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
