import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { mockDataService } from '../services/mockData';

const NOTE_TYPES = ['Note', 'Email', 'Call', 'Meeting'];
// const NOTE_STATUSES = ['Active', 'Hidden'];

function NoteDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [note, setNote] = useState(null);
  const [contact, setContact] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    title: '',
    content: '',
    type: '',
    tags: [],
  });
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const noteData = await mockDataService.getNoteById(parseInt(id));
      setNote(noteData);
      setEditForm({
        title: noteData.title,
        content: noteData.content,
        type: noteData.type,
        tags: [...noteData.tags],
      });
      
      const contacts = await mockDataService.getContacts();
      const foundContact = contacts.find(c => c.id === noteData.contactId);
      setContact(foundContact);
    };
    fetchData();
  }, [id]);

  const handleEdit = async (e) => {
    e.preventDefault();
    const updatedNote = await mockDataService.updateNote(parseInt(id), editForm);
    setNote(updatedNote);
    setIsEditing(false);
  };

  const handleDelete = async () => {
    await mockDataService.deleteNote(parseInt(id));
    navigate('/notes');
  };

  const handleAddTag = (e) => {
    e.preventDefault();
    if (newTag && !editForm.tags.includes(newTag)) {
      setEditForm(prev => ({
        ...prev,
        tags: [...prev.tags, newTag]
      }));
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setEditForm(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  if (!note || !contact) return (
    <div className="p-6 font-sans transition-colors duration-200 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
        Loading...
      </div>
    </div>
  );

  return (
    <div className="p-6 font-sans transition-colors duration-200 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
        {/* Header */}
        <div className="flex justify-between items-start mb-6">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-2">
              {note.title}
            </h1>
            <Link 
              to={`/contacts/${contact.id}`}
              className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 transition-colors duration-200 text-xs font-medium"
            >
              {contact.name}
            </Link>
          </div>
          <div className="space-x-2">
            <button
              onClick={() => setIsEditing(!isEditing)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </button>
            <button
              onClick={handleDelete}
              className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-red-600 dark:text-red-400 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              Delete
            </button>
          </div>
        </div>

        {/* Note Content */}
        {isEditing ? (
          <form onSubmit={handleEdit} className="space-y-6">
            <div>
              <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                Title
              </label>
              <input
                type="text"
                value={editForm.title}
                onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
              />
            </div>

            <div>
              <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                Content
              </label>
              <textarea
                value={editForm.content}
                onChange={(e) => setEditForm(prev => ({ ...prev, content: e.target.value }))}
                className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                rows="4"
              />
            </div>

            <div>
              <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                Type
              </label>
              <select
                value={editForm.type}
                onChange={(e) => setEditForm(prev => ({ ...prev, type: e.target.value }))}
                className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
              >
                {NOTE_TYPES.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                Tags
              </label>
              <div className="flex flex-wrap gap-2 mb-2">
                {editForm.tags.map(tag => (
                  <span
                    key={tag}
                    className="px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-xs flex items-center"
                  >
                    {tag}
                    <button
                      onClick={() => handleRemoveTag(tag)}
                      className="ml-1 text-blue-600 dark:text-blue-400 hover:text-blue-800"
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
              <div className="flex gap-2">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  className="block flex-1 px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                  placeholder="Add a tag"
                />
                <button
                  onClick={handleAddTag}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
                >
                  Add
                </button>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Save Changes
              </button>
            </div>
          </form>
        ) : (
          <div className="space-y-6">
            <div className="prose dark:prose-invert max-w-none">
              <p className="text-gray-600 dark:text-gray-300 text-xs">{note.content}</p>
            </div>

            <div className="flex items-center gap-4 text-xs text-gray-600 dark:text-gray-400">
              <span>{note.type}</span>
              <span>•</span>
              <span>{new Date(note.createdAt).toLocaleDateString()}</span>
              <span>•</span>
              <span>{note.status}</span>
            </div>

            <div className="flex flex-wrap gap-2">
              {note.tags.map(tag => (
                <span
                  key={tag}
                  className="px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-xs"
                >
                  {tag}
                </span>
              ))}
            </div>

            {note.attachments.length > 0 && (
              <div>
                <h3 className="text-xs font-medium text-gray-900 dark:text-white mb-2">
                  Attachments
                </h3>
                <div className="space-y-2">
                  {note.attachments.map(attachment => (
                    <div
                      key={attachment.id}
                      className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-700 rounded-md"
                    >
                      <span className="text-gray-900 dark:text-white text-xs">{attachment.name}</span>
                      <a
                        href={attachment.url}
                        download
                        className="text-blue-600 dark:text-blue-400 hover:text-blue-800 transition-colors duration-200 text-xs font-medium"
                      >
                        Download
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default NoteDetail;
