
const MOCK_DELAY = 1000;

const mockMessages = [
  {
    id: 1,
    role: 'assistant',
    content: 'Hello! How can I help you today?',
    timestamp: '2024-01-20T10:00:00Z'
  },
  {
    id: 2,
    role: 'user',
    content: 'Can you help me write a professional email to a client?',
    timestamp: '2024-01-20T10:01:00Z'
  },
  {
    id: 3,
    role: 'assistant',
    content: "I'd be happy to help you write a professional email. Could you provide me with:\n- The main purpose of the email\n- Key points you want to convey\n- Any specific tone preferences",
    timestamp: '2024-01-20T10:01:30Z'
  }
];

export const mockChatService = {
  getMessages: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockMessages);
      }, MOCK_DELAY);
    });
  },

  sendMessage: (content) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const newMessage = {
          id: Date.now(),
          role: 'assistant',
          content: `I received your message: "${content}". This is a mock response.`,
          timestamp: new Date().toISOString()
        };
        resolve(newMessage);
      }, MOCK_DELAY);
    });
  }
};