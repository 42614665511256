import { useState } from 'react';

function KanbanBoard() {
  const [boards, setBoards] = useState([
    { id: 'default', name: 'Default Board' },
    { id: 'sales', name: 'Sales Pipeline' }
  ]);
  const [currentBoard, setCurrentBoard] = useState('default');
  const [isEditMode, setIsEditMode] = useState(false);
  const [draggedCard, setDraggedCard] = useState(null);
  const [draggedOverColumn, setDraggedOverColumn] = useState(null);
  const [columns, setColumns] = useState({
    'todo': {
      id: 'todo',
      title: 'To Do',
      cards: [
        {
          id: 'card-1',
          title: 'Contact New Lead',
          brokerage: 'ABC Realty',
          contact: 'John Smith',
          note: 'Potential client interested in downtown properties'
        },
        {
          id: 'card-2',
          title: 'Schedule Property Viewing',
          brokerage: 'XYZ Properties',
          contact: 'Sarah Johnson',
          note: 'Client looking for 3-bedroom houses'
        }
      ]
    },
    'in-progress': {
      id: 'in-progress',
      title: 'In Progress',
      cards: [
        {
          id: 'card-3',
          title: 'Prepare Contract',
          brokerage: 'City Homes',
          contact: 'Mike Wilson',
          note: 'Draft purchase agreement for 123 Main St'
        },
        {
          id: 'card-4',
          title: 'Negotiate Terms',
          brokerage: 'ABC Realty',
          contact: 'John Smith',
          note: 'Counter-offer received for 456 Oak Ave'
        }
      ]
    },
    'done': {
      id: 'done',
      title: 'Done',
      cards: [
        {
          id: 'card-5',
          title: 'Close Deal',
          brokerage: 'XYZ Properties',
          contact: 'Sarah Johnson',
          note: 'Successfully closed 789 Pine St'
        },
        {
          id: 'card-6',
          title: 'Follow-up Meeting',
          brokerage: 'City Homes',
          contact: 'Mike Wilson',
          note: 'Client satisfaction review completed'
        }
      ]
    }
  });

  const [filters, setFilters] = useState({
    brokerage: '',
    contact: '',
    note: ''
  });

  const handleDragStart = (e, card, columnId) => {
    setDraggedCard({ card, sourceColumnId: columnId });
    e.dataTransfer.effectAllowed = 'move';
    e.target.style.opacity = '0.5';
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = '1';
    setDraggedOverColumn(null);
  };

  const handleDragOver = (e, columnId) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    setDraggedOverColumn(columnId);
  };

  const handleDrop = (e, targetColumnId) => {
    e.preventDefault();
    
    if (!draggedCard) return;

    const { card, sourceColumnId } = draggedCard;
    
    if (sourceColumnId === targetColumnId) {
      setDraggedCard(null);
      return;
    }

    const sourceColumn = columns[sourceColumnId];
    const targetColumn = columns[targetColumnId];
    
    const sourceCards = sourceColumn.cards.filter(c => c.id !== card.id);
    const targetCards = [...targetColumn.cards, card];

    setColumns({
      ...columns,
      [sourceColumnId]: {
        ...sourceColumn,
        cards: sourceCards
      },
      [targetColumnId]: {
        ...targetColumn,
        cards: targetCards
      }
    });

    setDraggedCard(null);
    setDraggedOverColumn(null);
  };

  const addNewBoard = () => {
    const boardId = `board-${Date.now()}`;
    setBoards([...boards, { id: boardId, name: `New Board ${boards.length + 1}` }]);
    setCurrentBoard(boardId);
  };

  const addNewColumn = () => {
    const columnId = `column-${Date.now()}`;
    setColumns({
      ...columns,
      [columnId]: {
        id: columnId,
        title: 'New Column',
        cards: []
      }
    });
  };

  const addNewCard = (columnId) => {
    const newCard = {
      id: `card-${Date.now()}`,
      title: 'New Card',
      brokerage: '',
      contact: '',
      note: ''
    };

    setColumns({
      ...columns,
      [columnId]: {
        ...columns[columnId],
        cards: [...columns[columnId].cards, newCard]
      }
    });
  };

  const deleteColumn = (columnId) => {
    const newColumns = { ...columns };
    delete newColumns[columnId];
    setColumns(newColumns);
  };

  const deleteBoard = () => {
    const newBoards = boards.filter(board => board.id !== currentBoard);
    if (newBoards.length > 0) {
      setBoards(newBoards);
      setCurrentBoard(newBoards[0].id);
    }
  };

  const handleSave = () => {
    setIsEditMode(false);
    // Here you would typically save the board state to your backend
  };

  const handleCancel = () => {
    setIsEditMode(false);
    // Here you might want to revert any changes made during edit mode
  };

  const filteredCards = (cards = []) => {
    return cards.filter(card => {
      const matchBrokerage = !filters.brokerage || 
        (card.brokerage && card.brokerage.toLowerCase().includes(filters.brokerage.toLowerCase()));
      const matchContact = !filters.contact || 
        (card.contact && card.contact.toLowerCase().includes(filters.contact.toLowerCase()));
      const matchNote = !filters.note || 
        (card.note && card.note.toLowerCase().includes(filters.note.toLowerCase()));
      
      return matchBrokerage && matchContact && matchNote;
    });
  };

  return (
    <div className="flex flex-col h-[calc(100vh-120px)]">
      {/* Fixed Header Section */}
      <div className="bg-white dark:bg-gray-900 px-4 py-4">
        {/* Board Selection and Actions */}
        <div className="flex items-center gap-2">
          <select
            value={currentBoard}
            onChange={(e) => setCurrentBoard(e.target.value)}
            className="px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm"
          >
            {boards.map(board => (
              <option key={board.id} value={board.id}>{board.name}</option>
            ))}
          </select>

          <button
            onClick={addNewBoard}
            className="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-sm"
          >
            New Board
          </button>

          <button
            onClick={() => setIsEditMode(!isEditMode)}
            className="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-sm"
          >
            Edit Board
          </button>

          {isEditMode && (
            <>
              <button
                onClick={addNewColumn}
                className="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-sm"
              >
                Add Column
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 rounded-lg bg-green-600 hover:bg-green-700 text-white text-sm"
              >
                Save
              </button>
              <button
                onClick={handleCancel}
                className="px-4 py-2 rounded-lg border border-gray-300 bg-white hover:bg-gray-50 text-gray-700 text-sm"
              >
                Cancel
              </button>
              <button
                onClick={deleteBoard}
                className="px-4 py-2 rounded-lg bg-red-600 hover:bg-red-700 text-white text-sm"
              >
                Delete
              </button>
            </>
          )}
        </div>
      </div>

      {/* Scrollable Kanban Board */}
      <div className="flex-1 overflow-x-auto overflow-y-hidden">
        <div className="inline-flex gap-4 p-4 min-w-full h-full">
          {Object.values(columns).map(column => (
            <div 
              key={column.id} 
              className="flex-shrink-0 w-80"
              onDragOver={(e) => handleDragOver(e, column.id)}
              onDrop={(e) => handleDrop(e, column.id)}
            >
              <div className={`bg-white dark:bg-gray-800 rounded-xl shadow-lg h-full flex flex-col border dark:border-gray-700 ${
                draggedOverColumn === column.id ? 'border-blue-500 dark:border-blue-400' : ''
              }`}>
                <div className="p-4 border-b border-gray-200 dark:border-gray-700">
                  <div className="flex justify-between items-center">
                    <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                      {column.title}
                    </h3>
                    {isEditMode && (
                      <button
                        onClick={() => deleteColumn(column.id)}
                        className="text-gray-500 hover:text-red-500"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>

                <div className="flex-1 overflow-y-auto p-4">
                  <div className="space-y-3">
                    {filteredCards(column.cards).map((card) => (
                      <div
                        key={card.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, card, column.id)}
                        onDragEnd={handleDragEnd}
                        className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 border border-gray-200 dark:border-gray-600 cursor-move"
                      >
                        <h4 className="text-sm font-semibold text-gray-800 dark:text-gray-100 mb-3">
                          {card.title}
                        </h4>
                        {card.brokerage && (
                          <div className="text-xs font-medium text-gray-700 dark:text-gray-200 mb-1">
                            <span className="text-blue-600 dark:text-blue-400">Brokerage:</span> {card.brokerage}
                          </div>
                        )}
                        {card.contact && (
                          <div className="text-xs font-medium text-gray-700 dark:text-gray-200 mb-1">
                            <span className="text-blue-600 dark:text-blue-400">Contact:</span> {card.contact}
                          </div>
                        )}
                        {card.note && (
                          <div className="text-xs font-medium text-gray-700 dark:text-gray-200">
                            <span className="text-blue-600 dark:text-blue-400">Note:</span> {card.note}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                  <button
                    onClick={() => addNewCard(column.id)}
                    className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    Add Card
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default KanbanBoard;
