import { useState } from 'react';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('Overview');
  const tabs = ['Overview', 'Analytics', 'Reports', 'Notifications'];

  const stats = [
    { title: 'Total Revenue', value: '$45,231.89', change: '+20.1% from last month', icon: '$' },
    { title: 'Subscriptions', value: '+2350', change: '+180.1% from last month', icon: '👤' },
    { title: 'Sales', value: '+12,234', change: '+19% from last month', icon: '💳' },
    { title: 'Active Now', value: '+573', change: '+201 since last hour', icon: '📈' },
  ];

  const recentSales = [
    { name: 'Olivia Martin', email: 'olivia.martin@email.com', amount: '+$1,999.00' },
    { name: 'Jackson Lee', email: 'jackson.lee@email.com', amount: '+$39.00' },
    { name: 'Isabella Nguyen', email: 'isabella.nguyen@email.com', amount: '+$299.00' },
    { name: 'William Kim', email: 'will@email.com', amount: '+$99.00' },
    { name: 'Sofia Davis', email: 'sofia.davis@email.com', amount: '+$39.00' },
  ];

  return (
    <div className="p-6 font-sans transition-colors duration-200 dark:bg-gray-900">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-gray-900 dark:text-white text-3xl font-bold tracking-tight">Dashboard</h2>
        <div className="flex items-center gap-4">
          <div className="bg-gray-100 dark:bg-gray-800 rounded-md py-2 px-4">
            <span className="text-sm text-gray-600 dark:text-gray-300">Jan 20, 2023 - Feb 09, 2023</span>
          </div>
          <button className="bg-gray-900 dark:bg-gray-100 text-white dark:text-gray-900 rounded-md px-4 py-2 text-sm transition-colors duration-200">
            Download
          </button>
        </div>
      </div>

      <div className="flex space-x-1 mb-8 border-b dark:border-gray-700">
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`px-4 py-2 text-sm rounded-t-lg transition-colors duration-200 ${
              activeTab === tab
                ? 'bg-white dark:bg-gray-800 text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-4 gap-6 mb-8">
        {stats.map((stat) => (
          <div key={stat.title} className="p-6 bg-white dark:bg-gray-800 rounded-xl border dark:border-gray-700 transition-colors duration-200">
            <div className="flex justify-between items-start mb-4">
              <p className="text-sm text-gray-500 dark:text-gray-400">{stat.title}</p>
              <span className="text-xl">{stat.icon}</span>
            </div>
            <p className="text-2xl font-semibold mb-2 dark:text-white">{stat.value}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">{stat.change}</p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-[2fr,1fr] gap-6">
        <div className="p-6 bg-white dark:bg-gray-800 rounded-xl border dark:border-gray-700 transition-colors duration-200">
          <h2 className="text-lg font-semibold mb-6 dark:text-white">Overview</h2>
          <div className="h-[300px] flex items-end space-x-2">
            {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month) => (
              <div key={month} className="flex-1 flex flex-col justify-end">
                <div className="bg-gray-900 dark:bg-gray-200 w-full transition-colors duration-200" style={{ height: `${Math.random() * 100}%` }}></div>
                <span className="text-xs text-gray-500 dark:text-gray-400 mt-2">{month}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="p-6 bg-white dark:bg-gray-800 rounded-xl border dark:border-gray-700 transition-colors duration-200">
          <h2 className="text-lg font-semibold mb-2 dark:text-white">Recent Sales</h2>
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">You made 265 sales this month.</p>
          <div className="space-y-6">
            {recentSales.map((sale) => (
              <div key={sale.email} className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 rounded-full"></div>
                  <div>
                    <p className="text-sm font-medium dark:text-white">{sale.name}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{sale.email}</p>
                  </div>
                </div>
                <p className="text-sm font-medium dark:text-white">{sale.amount}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
