import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { mockDataService } from '../services/mockData';

const NOTE_TYPES = ['Note', 'Email', 'Call', 'Meeting'];

function NewNote({ isSidebarOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [form, setForm] = useState({
    title: '',
    content: '',
    type: 'Note',
    contactId: '',
    tags: [],
    attachments: []
  });

  useEffect(() => {
    mockDataService.getContacts().then(setContacts);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!form.contactId) {
      alert('Please select a contact');
      return;
    }
    
    const newNote = await mockDataService.addNote({
      ...form,
      contactId: parseInt(form.contactId)
    });
    
    toggleSidebar();
    navigate(`/notes/${newNote.id}`);
  };

  const handleAddTag = (e) => {
    e.preventDefault();
    if (newTag && !form.tags.includes(newTag)) {
      setForm(prev => ({
        ...prev,
        tags: [...prev.tags, newTag]
      }));
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setForm(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  return (
    <>
      <div className={`fixed inset-0 z-40 ${isSidebarOpen ? '' : 'pointer-events-none'}`}>
        <div
          className={`absolute inset-0 transition-opacity duration-300 ${isSidebarOpen ? 'opacity-50' : 'opacity-0'}`}
          onClick={toggleSidebar}
        >
          <div className="absolute inset-0 bg-black"></div>
        </div>
        <div
          className={`fixed inset-y-0 right-0 w-full md:w-[40rem] bg-white dark:bg-gray-800 shadow-xl transform transition-transform duration-300 ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}
        >
          <div className="p-6 border-b dark:border-gray-700">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Add New Note</h2>
              <button
                onClick={toggleSidebar}
                className="text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors duration-200"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div className="p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Title */}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Title
                </label>
                <input
                  type="text"
                  required
                  value={form.title}
                  onChange={(e) => setForm(prev => ({ ...prev, title: e.target.value }))}
                  className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                  placeholder="Enter note title"
                />
              </div>

              {/* Content */}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Content
                </label>
                <textarea
                  required
                  value={form.content}
                  onChange={(e) => setForm(prev => ({ ...prev, content: e.target.value }))}
                  className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                  rows="4"
                  placeholder="Enter note content"
                />
              </div>

              {/* Type */}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Type
                </label>
                <select
                  value={form.type}
                  onChange={(e) => setForm(prev => ({ ...prev, type: e.target.value }))}
                  className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                >
                  {NOTE_TYPES.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>

              {/* Contact */}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Contact
                </label>
                <select
                  required
                  value={form.contactId}
                  onChange={(e) => setForm(prev => ({ ...prev, contactId: e.target.value }))}
                  className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                >
                  <option value="">Select a contact</option>
                  {contacts.map(contact => (
                    <option key={contact.id} value={contact.id}>
                      {contact.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Tags */}
              <div>
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-200 mb-2">
                  Tags
                </label>
                <div className="flex flex-wrap gap-2 mb-2">
                  {form.tags.map(tag => (
                    <span
                      key={tag}
                      className="px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-xs flex items-center"
                    >
                      {tag}
                      <button
                        type="button"
                        onClick={() => handleRemoveTag(tag)}
                        className="ml-1 text-blue-600 dark:text-blue-400 hover:text-blue-800 transition-colors duration-200"
                      >
                        ×
                      </button>
                    </span>
                  ))}
                </div>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    className="block flex-1 px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                    placeholder="Add a tag"
                  />
                  <button
                    type="button"
                    onClick={handleAddTag}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
                  >
                    Add
                  </button>
                </div>
              </div>

              {/* Submit Button */}
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={toggleSidebar}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-blue-600 hover:bg-blue-700"
                >
                  Create Note
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewNote;
