import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { mockDataService } from '../services/mockData';
import ContactDetails from '../components/ContactDetails';
import companiesData from '../data/companies.json';

function ContactSearch() {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]); // Add this line
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [selectedStatus, setSelectedStatus] = useState('');
  const observer = useRef();
  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false);
  const statusFilterRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companySearchValue, setCompanySearchValue] = useState('');
  const filterRef = useRef(null);

  // Sort companies by name
  const sortedCompanies = [...companiesData.Export].sort((a, b) => 
    a['Company Name'].localeCompare(b['Company Name'])
  );

  // Filter companies based on search input
  const filteredCompanies = sortedCompanies.filter(company =>
    company['Company Name'].toLowerCase().includes(companySearchValue.toLowerCase())
  );

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleCompanySelect = (companyId) => {
    if (companyId === selectedCompany) {
      setSelectedCompany(null);
      setIsFilterOpen(false);
      return;
    }

    setSelectedCompany(companyId);
    setIsFilterOpen(false);
  };

  const clearFilter = () => {
    setSelectedCompany(null);
  };

  // Get the selected company name for display
  const selectedCompanyName = selectedCompany 
    ? sortedCompanies.find(c => c['Company ID'] === selectedCompany)?.['Company Name']
    : null;

  // Add click outside handler
  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (statusFilterRef.current && !statusFilterRef.current.contains(event.target)) {
        setIsStatusFilterOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getStatusInfo = (status) => {
    switch (status) {
      case 'New':
        return {
          color: 'text-green-600 dark:text-green-500',
          icon: (
            <svg className="w-4 h-4 text-green-600 dark:text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
      case 'Trial':
        return {
          color: 'text-orange-600 dark:text-orange-500',
          icon: (
            <svg className="w-4 h-4 text-orange-600 dark:text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
      case 'Current':
        return {
          color: 'text-blue-600 dark:text-blue-500',
          icon: (
            <svg className="w-4 h-4 text-blue-600 dark:text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          )
        };
      case 'Lost':
        return {
          color: 'text-red-600 dark:text-red-500',
          icon: (
            <svg className="w-4 h-4 text-red-600 dark:text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
      default:
        return {
          color: 'text-gray-600 dark:text-gray-400',
          icon: (
            <svg className="w-4 h-4 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        };
    }
  };

  const toggleRow = (id) => {
    setExpandedRows(prev => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  const lastElementCallback = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    setLoading(true);
    mockDataService.getContacts(page, 20)
      .then(newContacts => {
        setContacts(prev => {
          const existing = new Set(prev.map(c => c.id));
          const filtered = newContacts.filter(c => !existing.has(c.id));
          return [...prev, ...filtered];
        });
        setHasMore(newContacts.length > 0);
        setLoading(false);
      });
  }, [page]);

  // Add this useEffect for filtering
  useEffect(() => {
    const filtered = contacts.filter(contact => {
      const matchesCompany = selectedCompany 
        ? contact.company === sortedCompanies.find(c => c['Company ID'] === selectedCompany)?.['Company Name']
        : true;
      const matchesStatus = selectedStatus 
        ? contact.leadStatus === selectedStatus 
        : true;
      return matchesCompany && matchesStatus;
    });
    setFilteredContacts(filtered);
  }, [contacts, selectedCompany, selectedStatus, sortedCompanies]);

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-gray-900 dark:text-white text-3xl font-bold tracking-tight">Contacts</h2>
        
        {/* Add Filter Controls */}
        <div className="relative" ref={filterRef}>
          <div className="flex space-x-2">
            {selectedCompany && (
              <button
                onClick={clearFilter}
                className="inline-flex items-center px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                title={`Clear filter: ${selectedCompanyName}`}
              >
                <span className="mr-2">{selectedCompanyName}</span>
                <svg className="h-5 w-5 text-gray-400 dark:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            )}
            <button 
              onClick={toggleFilter}
              className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <svg className="h-5 w-5 text-gray-400 dark:text-gray-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
              </svg>
              FILTER {selectedCompany && '(1)'}
            </button>
          </div>

          {/* Filter dropdown */}
          {isFilterOpen && (
            <div className="absolute top-full right-0 mt-2 w-96 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-10">
              <div className="p-4">
                <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100 mb-3">Select Company</h3>
                <div className="mb-3">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                      <svg className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      value={companySearchValue}
                      onChange={(e) => setCompanySearchValue(e.target.value)}
                      placeholder="Search"
                      className="block w-full pl-11 pr-10 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
                    />
                    {companySearchValue && (
                      <button
                        onClick={() => setCompanySearchValue('')}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      >
                        <svg className="h-4 w-4 text-gray-400 hover:text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                <div className="space-y-2 max-h-96 overflow-y-auto">
                  {filteredCompanies.map(company => (
                    <label key={company['Company ID']} className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600 rounded"
                        checked={selectedCompany === company['Company ID']}
                        onChange={() => handleCompanySelect(company['Company ID'])}
                      />
                      <span className="ml-2 text-sm text-gray-700 dark:text-gray-200">
                        {company['Company Name']}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
        <div className="w-full">
          <table className="w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead>
              <tr className="bg-gray-50 dark:bg-gray-800">
                <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" className="hidden xl:table-cell px-6 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Company
                </th>
                <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  <div className="flex items-center justify-between relative" ref={statusFilterRef}>
                    <span>Status</span>
                    <button 
                      onClick={() => setIsStatusFilterOpen(!isStatusFilterOpen)}
                      className="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300"
                    >
                      <svg className={`w-4 h-4 transform transition-transform ${isStatusFilterOpen ? 'rotate-180' : ''}`} 
                           fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    {isStatusFilterOpen && (
                      <div className="absolute right-0 top-full mt-1 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-20">
                        <div className="py-1">
                          {['New', 'Trial', 'Current', 'Lost'].map(status => (
                            <button
                              key={status}
                              onClick={() => {
                                setSelectedStatus(status);
                                setIsStatusFilterOpen(false);
                              }}
                              className={`block w-full px-4 py-2 text-sm text-left ${
                                selectedStatus === status 
                                  ? 'bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white' 
                                  : 'text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700'
                              }`}
                            >
                              <span className={`inline-flex items-center gap-1.5 ${getStatusInfo(status).color}`}>
                                {getStatusInfo(status).icon}
                                {status}
                              </span>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </th>
                <th scope="col" className="hidden md:table-cell px-6 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Email
                </th>
                <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Phone
                </th>
                <th scope="col" className="relative w-16 px-6 py-2">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {filteredContacts.map((contact, index) => (
                <React.Fragment key={contact.id}>
                  <tr 
                    ref={index === contacts.length - 1 ? lastElementCallback : null}
                    className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                    onClick={() => toggleRow(contact.id)}
                  >
                    <td className="px-6 py-2.5 whitespace-nowrap">
                      <Link 
                        to={`/contacts/${contact.id}`}
                        className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {contact.name}
                      </Link>
                    </td>
                    <td className="hidden xl:table-cell px-6 py-2.5 whitespace-nowrap">
                      <div className="text-sm text-gray-600 dark:text-gray-300">{contact.company}</div>
                    </td>
                    <td className="px-6 py-2.5 whitespace-nowrap">
                      <div className="flex items-center gap-1.5">
                        {getStatusInfo(contact.leadStatus).icon}
                        <span className={`text-sm ${getStatusInfo(contact.leadStatus).color}`}>
                          {contact.leadStatus}
                        </span>
                      </div>
                    </td>
                    <td className="hidden md:table-cell px-6 py-2.5 whitespace-nowrap">
                      <div className="text-sm text-gray-600 dark:text-gray-300">{contact.email}</div>
                    </td>
                    <td className="px-6 py-2.5 whitespace-nowrap">
                      <div className="text-sm text-gray-600 dark:text-gray-300">{contact.phone}</div>
                    </td>
                    <td className="px-6 py-2.5 whitespace-nowrap text-right">
                      <button className="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300">
                        <svg 
                          className={`w-4 h-4 transform transition-transform ${expandedRows.has(contact.id) ? 'rotate-180' : ''}`} 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                  {expandedRows.has(contact.id) && (
                    <tr>
                      <td colSpan="6" className="p-0 border-t border-gray-100 dark:border-gray-700">
                        <ContactDetails contact={contact} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        {loading && (
          <div className="bg-white dark:bg-gray-800 px-6 py-4 border-t border-gray-200 dark:border-gray-700">
            <div className="flex justify-center">
              <div className="animate-pulse text-gray-500 dark:text-gray-400">Loading...</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactSearch;
