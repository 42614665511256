import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

function Sidebar() {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const navigationItems = [
    { 
      to: '/', 
      icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />, 
      label: 'Dashboard' 
    },
    { 
      to: '/contacts', 
      icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a4 4 0 00-3-3.87M9 20H4v-2a4 4 0 013-3.87m8-4a4 4 0 11-8 0 4 4 0 018 0z" />, 
      label: 'Contacts' 
    },
    { 
      to: '/notes', 
      icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />, 
      label: 'Notes' 
    },
    { 
      to: '/kanban', 
      icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />, 
      label: 'Kanban' 
    },
    { 
      to: '/settings', 
      icon: <>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      </>, 
      label: 'Settings' 
    }
  ];

  return (
    <>
      {/* Mobile Bottom Navigation */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 h-16 bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-700 z-[9999]">
        <nav className="h-full px-2 py-1">
          <div className="flex justify-around items-center h-full">
            {navigationItems.map(({ to, icon, label }) => (
              <Link 
                key={to}
                to={to} 
                className={`flex flex-col items-center p-1.5 rounded-lg ${
                  isActive(to) 
                    ? 'bg-blue-600 text-white' 
                    : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white'
                }`}
              >
                <div className="w-6 h-6 flex items-center justify-center">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    {icon}
                  </svg>
                </div>
                <span className="text-[10px] mt-0.5">{label}</span>
              </Link>
            ))}
          </div>
        </nav>
      </div>

      {/* Desktop Sidebar */}
      <div 
        className={`hidden md:flex md:flex-col md:h-screen bg-white dark:bg-gray-900 transition-all duration-300 ease-in-out border-r border-gray-200 dark:border-gray-700 sticky top-0 ${
          isCollapsed ? 'w-16' : 'w-52'
        }`}
      >
        <div className="p-4 mb-8">
          <Link to="/" className="flex items-center">
            <svg className="text-blue-500 w-8 h-8 ml-1.5" viewBox="0 0 24 24" fill="currentColor">
              <rect x="3" y="3" width="7" height="7" />
              <rect x="14" y="3" width="7" height="7" />
              <rect x="3" y="14" width="7" height="7" />
              <rect x="14" y="14" width="7" height="7" />
            </svg>
          </Link>
        </div>
        
        <nav className="flex-1 px-2">
          <div className="flex flex-col space-y-1">
            {navigationItems.map(({ to, icon, label }) => (
              <Link 
                key={to}
                to={to} 
                className={`flex items-center h-10 px-3 rounded-lg transition-colors duration-150 ${
                  isActive(to) 
                    ? 'bg-blue-600 text-white' 
                    : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white'
                }`}
              >
                <div className="w-6 h-6 flex items-center justify-center flex-shrink-0">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    {icon}
                  </svg>
                </div>
                <span className={`ml-3 text-sm font-medium whitespace-nowrap transition-opacity duration-150 ${
                  isCollapsed ? 'opacity-0 w-0 overflow-hidden' : 'opacity-100'
                }`}>
                  {label}
                </span>
              </Link>
            ))}
          </div>
        </nav>

        <div className="px-2 py-4 mt-auto">
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="flex items-center p-2 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {isCollapsed ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              )}
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
