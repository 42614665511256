import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import ContactSearch from './pages/ContactSearch';
import ContactDetails from './pages/Contact';
import NoteSearch from './pages/NoteSearch';
import NoteDetail from './pages/NoteDetail';
import KanbanBoard from './pages/KanbanBoard';
import Sidebar from './components/Sidebar';
import FloatingActionButton from './components/FloatingActionButton';
import Chatbot from './components/Chatbot';
import { ThemeProvider } from './context/ThemeContext';

function App() {
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
  // Remove selectedCompany state
  // const [selectedCompany, setSelectedCompany] = useState(null);

  const toggleRightPanel = () => {
    setIsRightPanelOpen(!isRightPanelOpen);
  };

  return (
    <ThemeProvider>
      <Router>
        <div className="min-h-screen flex flex-col bg-white dark:bg-gray-800">
          <div className="flex-1 flex flex-col md:flex-row">
            {/* Sidebar - Hidden on mobile when chatbot is open */}
            <aside className={`md:sticky md:top-0 md:h-screen flex-shrink-0 border-r border-gray-200 dark:border-gray-700 transition-all duration-300 ${
              isRightPanelOpen ? 'hidden md:block' : 'block'
            }`}>
              <Sidebar />
            </aside>
            
            {/* Main content area with header */}
            <div className="flex-1 flex flex-col w-full">
              {/* Header - Keep outside of scrollable area */}
              <div className="sticky top-0 z-[60] border-b border-gray-200 dark:border-gray-700 w-full bg-white dark:bg-gray-800">
                <div className="max-w-[1920px] mx-auto w-full">
                  <Navbar 
                    toggleRightPanel={toggleRightPanel} 
                    isRightPanelOpen={isRightPanelOpen} 
                    // Remove onCompanyFilter prop
                  />
                </div>
              </div>
              
              {/* Main content - Adjust height to account for header */}
              <main className="flex-1 bg-gray-50 dark:bg-gray-900">
                <div className="h-[calc(100vh-64px)] md:h-[calc(100vh-64px)] flex flex-col md:flex-row relative">
                  {/* Left column - Content */}
                  <div className={`h-full overflow-y-auto transition-all duration-300 ease-in-out ${
                    isRightPanelOpen 
                      ? 'hidden md:block md:w-2/3' 
                      : 'w-full'
                  }`}>
                    <div className="max-w-full mx-auto px-4 md:px-6">
                      <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/contacts" element={<ContactSearch />} /> {/* Remove selectedCompany prop */}
                        <Route path="/contacts/:id" element={<ContactDetails />} />
                        <Route path="/notes" element={<NoteSearch />} />
                        <Route path="/notes/:id" element={<NoteDetail />} />
                        <Route path="/kanban" element={<KanbanBoard />} />
                      </Routes>
                    </div>
                  </div>

                  {/* Right column - Chatbot */}
                  <div 
                    className={`fixed md:relative flex flex-col transition-all duration-300 ease-in-out bg-white dark:bg-gray-800 border-l border-gray-200 dark:border-gray-700 h-[calc(100vh-64px)] md:h-[calc(100vh-64px)] z-[50] top-[64px] md:top-0 right-0 ${
                      isRightPanelOpen 
                        ? 'translate-x-0 w-full md:w-1/3 opacity-100 visible' 
                        : 'translate-x-full md:w-0 opacity-0 invisible md:opacity-100'
                    }`}
                  >
                    <div className="flex-1 relative overflow-hidden">
                      <div className="absolute inset-0 overflow-hidden">
                        <Chatbot onClose={toggleRightPanel} />
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <FloatingActionButton />
            </div>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
